import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppCFOsPriorityRest } from './AppCFOsPriorityRest'

const AppCFOsPriorityWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .AppCFOsPrioritySection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .AppCFOsPriorityNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .AppCFOsPrioritySectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppCFOsPriorityWithNav = () => {
  return (
    <AppCFOsPriorityWithNavWapper>
      <div className='AppCFOsPrioritySection'>
        <div className='AppCFOsPriorityNav'>
          <AppSideNavEmailName />
        </div>
        <div className='AppCFOsPrioritySectionContent'>
          <AppCFOsPriorityRest />
        </div>
      </div>
    </AppCFOsPriorityWithNavWapper>
  )
}
