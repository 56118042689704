import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Icon: <FaArrowCircleRight />,
    ParaOne: "So, what do CFOs consider to be their top priority? “Making the right digital investments used to get CFOs extra credit,” says Alexander Bant, Chief of Research for CFOs at Gartner. “In 2022, successful digital transformation will become the baseline for even, solid performance for all finance leaders.” CFOs need to leverage digital initiatives and support different verticals by acquiring modern technology to solve today’s critical issues.",
    ParaTwo: "Raindrop enables CFOs and Finance executives to have a unified view on corporate spend and provides visibility and actionable intelligence into purchase commitments, supplier spend, market trends, and much more. As a CFO or Finance executive, instantly gain spend visibility and seamlessly integrate with the procurement process to help control financial commitments departing the company.",
    HeadingOne: "Raindrop Spend Management Platform as the Centerpiece Driving and Achieving CFO’s Priorities.",
    HeadingTwo: "1. Financial Performance of Organization & Business Growth",
    ParaThree: "To improve financial performance and boost their companies’ bottom line profits, CFOs need to utilize or promote digital solutions to increase efficiencies, manage profitability, and hit transformational milestones. A key task for CFOs will be re-allocating capital based on the changing demands, the flexibility of budgeting and forecasting, and improving returns on enterprise digital investments. There must be flexibility, agility, and a strategic thought process involved in plans and budgets so that businesses can swiftly respond to unexpected disruptions and opportunities. With Raindrop, CFOs can get one-click access to spend visibility across departments and automated data analytics for better decision making. Real-time accurate spend forecasts to manage risk and meet capital and cost management goals. Raindrop provides a 360-degree view across different dimensions of spend, inclusive of commodity acquisition patterns, predictable opportunities, and risk involved with external supplier spend, visibility into commodity consumption, recover costs from under-utilized commodities, and contain costs before over-consumption.",
    HeadingThree: "2. Strategy Setting, Cost Management & Capital Allocation",
    ParaFour: "The need for rethinking capital allocation has intensified due to the pandemic, supply chain disruptions, global trade uncertainty, etc. A majority of CFOs believe that they need to improve their capital allocation strategy, but accessing data is the main challenge. Raindrop’s Spend Management system allows businesses to analyze and plan their corporate spending in an efficient and intelligent way. Raindrop allows Finance leaders to have real-time visibility into enterprise spending and manage fiscal commitments proactively. Raindrop provides 360-degree supplier insights and transparency, who in your company are buying things, from which suppliers, and track where your contractual obligations are going. Raindrop’s powerful business intelligence enables CFOs to plan and manage commodities, spend categories, create supplier consolidation strategies, and build predictive sourcing strategies across each functional area. Translate your capital allocation strategy to action and measure adherence to the published plan.",
    HeadingFour: "3. Compliances & Regulatory Readiness",
    ParaSix: "Today’s business environment requires CFOs to inject more flexibility in investment governance and supply base governance. The use of digital solutions to monitor compliance and governance is a key priority for CFOs in 2022. The use of smart technologies to establish business unit delegation of authority or supplier compliance is a key driver to adopt the digital solution for compliance and regulatory readiness. Diversity and inclusion, risk management, information security, compliance, quality, performance, and numerous other categories are also leading the focus. CFOs understand the criticality and need for digital solutions to track and facilitate compliance readiness associated with the respective function. Raindrop helps provide CFO’s a 360-degree view of your suppliers and provides insights into compliance requirements. With Raindrop stay on top with your supplier’s compliance and create a streamlined governance process to identify or mitigate any risks. Raindrop provides the necessary tools and workflows needed to control your spend by creating calls to action. The configurable workflows help to streamline the approval process based on your internal delegation of authority with a full audit trial.",
    HeadingFive: "4. Supply Chain, Process & Operations",
    ParaSeven: "In 2021, major issues of port congestions, labor shortages, and sky-rocketing demands created havoc for the supply chains. As supply chain management has been growing increasingly complex, CFOs and Sourcing leaders have taken on a higher degree of criticality by adopting technology-driven solutions to maximize fully-integrated spend transparency. Enterprise Spend Management technologies such as Raindrop deliver increased efficiencies, reduced cycle times, enhanced internal and external collaboration, materials consumption visibility, and higher transparency across all contractual obligations. Raindrop’s cloud-based intelligent, scalable, and secured solution provides real-time visibility in the supply chain for proactive decision-making to manage products, people, and processes in a better and digitized way.",
    HeadingSix: "5. Digital Transformation, Data Analytics & AI",
    ParaEightLeft: "In order to prepare for technology-driven finance operations, CFOs believe they must dive into digital transformation, according to",
    ParaAnchor:"Gartner,",
    ParaEightRight:"82% of CFOs state that their investments in the digital transformation space are rapidly growing, which will eventually enhance their competitive advantage in the coming years. 86 % commit that AI is becoming their main technology investment, which can help in complex activities like budgeting and forecasting. Similar to how real-time data is changing the value proposition for Finance leaders, the demand is being delivered via new technologies simply not previously available. This reality in new capabilities is driven by “Finance leaders have been asking more and more about artificial intelligence (AI), machine learning, and advanced analytics.",
    AnchorShift:"https://www.gartner.com/en/articles/these-are-the-top-cfo-priorities-for-2022",
    ParaNine: "Raindrop’s Spend Management solution is powered by artificial intelligence (AI) and machine learning (ML), providing visibility and unparalleled capabilities in controlling how and who spends money at your company. Raindrop digitizes your Procurement processes and is created to focus on delivering efficiencies, be they cost savings, cost avoidance, or other programmatic benefits. Raindrop seamlessly and readily integrates with all major ERP systems, and other central data sources to create a unified and normalized view of company spend. Raindrop’s easy-to-use interface enables users to address data as desired, further enabling you to create and share reports with several layers of analytics that are very easily accessible.",
  },
]
