import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'

export const ReuseImgTag = (props: any) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <>
      <motion.img
        className={props.ImageStyle}
        src={props.ImagePath}
        alt={props.AltName}
        ref={ref}
        animate={controls}
        initial='hidden'
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 0.5, delay: 0.35 }}
      />
    </>
  )
}
