import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import CFOsPriorityRestData from '../Data/CFOsPriorityRestData'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReUseHOneTag } from './ReUseHOneTag'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'

const AppCFOsPriorityRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .ContractsModuleSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .HeadingEamilContractsModule {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaContractsModuleStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaContractsModuleStyleOne{
    max-width: 1150px;
    padding: 0px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .SubParaPonits {
    color: #047cc2;
    padding: 10px 0px 10px 0px;
    font-weight: bold;
    font-size: 21px;
  }
  .CFOsPriorityachorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }

    .CFOsPriorityachorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .CFOsPriorityParaStyle{
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .ContactBannerHeading{
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;}
  }
`

export const AppCFOsPriorityRest = () => {
  return (
    <AppCFOsPriorityRestWapper>
      <div>
        {CFOsPriorityRestData.map((data, index) => {
          return (
            <div className='MainEmailProcurementSection' key={index}>
              <div className='ContractsModuleSectionEmail'>
                <ReUsePtag para={data.ParaOne} paraStyle='LeadParaContractsModuleStyleOne' />
                <ReUsePtag para={data.ParaTwo} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHOneTag Heading={data.HeadingOne} HeadingStyle='ContactBannerHeading' />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaThree} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaFour} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingFour} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaSix} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingFive} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtag para={data.ParaSeven} paraStyle='LeadParaContractsModuleStyle' />
                <ReUseHTwotag Heading={data.HeadingSix} HeadingStyle='HeadingEamilContractsModule' />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaEightLeft}
                  anchorPara={data.ParaAnchor}
                  ParaRight={data.ParaEightRight}
                  href={data.AnchorShift}
                  target='_blank'
                  rel='noopener noreferrer'
                  anchorStyle='CFOsPriorityachorPara'
                  paraStyle='CFOsPriorityParaStyle'
                />
                <ReUsePtag para={data.ParaNine} paraStyle='LeadParaContractsModuleStyle' />
              </div>
            </div>
          )
        })}
      </div>
    </AppCFOsPriorityRestWapper>
  )
}
