import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { ReUseAnchorTag } from './ReUseAnchorTag'
import styled from 'styled-components'

export interface PtagWithAnchorProps {
  href?: string
  target?: string
  rel?: string
  ParaLeft?: string
  ParaRight?: string
  anchorPara?: string
  id?: string
  anchorStyle?: any
  children?: any
  paraStyle?: any
}

const ReUsePtagwithAnchorTagWapper = styled.div`
  p {
    /* color: rgba(52, 61, 72, 0.8); */
    color: #071741;
  }
`

export const ReUsePtagwithAnchorTag = (props: PtagWithAnchorProps) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <ReUsePtagwithAnchorTagWapper>
      <motion.p
        id={props.id}
        className={props.paraStyle}
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 0.5, delay: 0.35 }}
      >
        {props.ParaLeft}
        <ReUseAnchorTag href={props.href} target={props.target} anchorStyle={props.anchorStyle} rel={props.rel}>
          {props.anchorPara}
        </ReUseAnchorTag>
        {props.ParaRight}
      </motion.p>
    </ReUsePtagwithAnchorTagWapper>
  )
}
