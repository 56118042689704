import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHOneTag } from './ReUseHOneTag'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'

const AppResourcesEmailBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
`

export const AppResourcesEmailBanner = (props: any) => {
  return (
    <AppResourcesEmailBannerWapper>
      <div className={props.BannerSection}>
        <div>
          <ReUseHOneTag HeadingStyle={props.BannerHeading} Heading={props.Heading} />
          <ReUsePtag para={props.DatePara} paraStyle={props.DateBannerPara} />
          <ReUsePtag para={props.Para} paraStyle={props.BannerPara} />
          <ReUsePtagwithAnchorTag
            ParaLeft={props.LeadParaLeft}
            anchorPara={props.LeadAnchorPara}
            ParaRight={props.LeadParaRight}
            href={props.ShiftUrlHref}
            target='_blank'
            rel='noopener noreferrer'
            anchorStyle={props.achorPara}
            paraStyle={props.LeadParaStyle}
          />
        </div>
        <div className={props.BannerImageSection}>
          <ReuseImgTag ImageStyle={props.BannerImage} ImagePath={props.ImagePath} AltName={props.AltText} />
        </div>
      </div>
    </AppResourcesEmailBannerWapper>
  )
}
